import React from 'react';
import DividerImage from '../images/divider.png';

const Divider = () => {
  return (
    <img src={DividerImage} alt="stylized divider line" />
  );
}

export default Divider;
