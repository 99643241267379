import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Divider from '../components/divider';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import '../components/css/success-stories.css';

const SuccessStoriesPage = ({data}) => {
  return (
    <Layout>
      <SEO title="Success Stories" description="Pauly Group's 'customer service, attention to details and the ultimate outcomes were remarkable.' Fritz Larson, Chair, Black Hawk College, Board of Trustees" />
      <div className="success -container">
        <div className="success -header">
          <h1>Success Stories</h1>
          <Divider alt="Decorative divider line"/>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" /> Black Hawk College</h4>
          <h4 className="success -subheader">Presidential & Executive Director Search</h4>
          <div className="success -story-container">
            <p>"Black Hawk College and the Illinois
            Community College Trustees Association have utilized the services of
            the Pauly Group for our College Presidential (Spring 2018) and
            Executive Director Search’s (Spring 2019) respectively. Their customer
            service, attention to details and the ultimate outcomes were remarkable.
            Should your organization need to acquire executive talent, the Pauly
            Group delivers!"</p>
          </div>
          <h4 className="success -author">- Fritz Larson</h4>
          <h4 className="success -position">Immediate Past President, Illinois Community College Trustees Association</h4>
          <h4 className="success -position">Chair, Black Hawk College, Board of Trustees</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />Central New Mexico Community College</h4>
          <h4 className="success -subheader">Presidential Search</h4>
          <div className="success -story-container">
            <p>"Pauly Group did a fine job as it assisted
            the Governing Board of Central New Mexico Community College in our
            presidential search. We truly appreciated the caliber of candidates
            presented. The screening process provided highly qualified people
            for interview. In addition, the vetting process with community and
            college leaders and representatives that led to our finalists was
            thoughtful and thorough."</p>
          </div>
          <h4 className="success -author">- Pauline Garcia</h4>
          <h4 className="success -position">Governing Board Chair</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />College of Lake County</h4>
          <h4 className="success -subheader">Presidential Search</h4>
          <div className="success -story-container">
            <p>"Pauly Group gave us a customized,
            professional search where it was easier to make our decision and
            feel good when the search was over."</p>
          </div>
          <h4 className="success -author">- Rich Anderson</h4>
          <h4 className="success -position">Secretary, College of Lake County Board of Trustees</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />Holyoke Community College</h4>
          <h4 className="success -subheader">Presidential Search</h4>
          <div className="success -story-container">
            <p>"I have had great success working with the Pauly Group, both as a
            candidate and also in retaining their services for academic administrator
            positions. From a candidate perspective, Angela and her team were
            beneficial in helping me to understand the priorities and desires of
            the institutions in which I was applying, and provided great consultation
            to ensure that I was only considering colleges that aligned with my
            values and preferences, and institutions that could benefit from my
            strengths and experiences. They provided insight and guidance on
            every phase of the interview process, from application to job offer,
            and really strive to know candidates beyond their curriculum vitas."</p>
          </div>
          <h4 className="success -author">- Christina Royal, Ph.D.</h4>
          <h4 className="success -position">President</h4>
          <div className="success -story-container">
            <p>"I strongly recommend The Pauly Group
            for anyone seeking to fill a high level position at a community college.
            We used The Pauly Group for a search for a new president at Holyoke
            Community College, and we couldn't have been happier with both the
            process and the result. The Pauly Group specializes in working with
            community colleges, so their large network of people to reach out to
            meant we had a very strong candidate pool. They were invaluable in
            helping us to prioritize candidates, develop effective questions for
            the interviewing process, arrange for and hold the interviews, and
            have thorough discussions to come to consensus about who we wanted
            to hire. Best of all, I hear regular praise about our new president
            from people with widely varying roles in relationship to the college."</p>
          </div>
          <h4 className="success -author">- Julie Pokela</h4>
          <h4 className="success -position">Secretary, Board of Trustees and Presidential Search Committee Chair</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />Lake Michigan College</h4>
          <h4 className="success -subheader">Presidential Search</h4>
          <div className="success -story-container">
            <p>"Pauly Group did an outstanding job
            assisting us with our recent presidential search. Hiring a firm with
            community college expertise made all the difference in the world.
            The insights they had regarding candidates, personalities and
            backgrounds was worth its' weight in gold."</p>
          </div>
          <h4 className="success -author">- Mary Jo Tomasini</h4>
          <h4 className="success -position">Former Board Chair</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />Lake Michigan College</h4>
          <h4 className="success -subheader">Presidential Candidate</h4>
          <div className="success -story-container">
            <p>"The team at Pauly Group strive to
            bring the right candidate to the right position. As a candidate,
            they made the process easy. They provided material requested, laid
            out clear timelines and decision points, made sure technology was
            checked before interviews, and were supportive and up front to the
            final result. I am in the right job at the right institution thanks
            to their service."</p>
          </div>
          <h4 className="success -author">- Trevor A. Kubatzke, Ph.D</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />Lakeshore Technical College</h4>
          <h4 className="success -subheader">Presidential Search</h4>
          <div className="success -story-container">
            <p>"We selected the Pauly Group over two
            other search firms we interviewed for our Presidential Search at Lakeshore
            Technical College in Wisconsin. We know we made the right choice.
            From soliciting potential applicants, to screening and categorizing
            the applications, to scheduling interviews and conducting background
            checks, what could have been a very daunting and time consuming process
            was made extremely manageable. Angela and her team are very organized
            and attentive to important details. Communication with them was timely
            and transparent. Our search committee found using their secure website
            to review the application materials was very easy. We had a very
            strong applicant pool and selected an outstanding candidate to be
            our next President. I do not believe we would have had the success
            we experienced without the Pauly Group. I highly recommend them."</p>
          </div>
          <h4 className="success -author">- Sharon Chappy, Ph.D.</h4>
          <h4 className="success -position">Former Chairperson, Lakeshore Technical College District Board</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />Lakeshore Technical College</h4>
          <h4 className="success -subheader">Presidential Candidate</h4>
          <div className="success -story-container">
            <p>"As a candidate, I found the Pauly Group
            extremely responsive throughout the entire search process. Angela
            and her team were readily available to answer questions, provide
            feedback, and give advice. It was clear from the beginning of the
            process that the Pauly Group wanted me to succeed as a candidate."</p>
          </div>
          <h4 className="success -author">- Dr. Paul Carlsen</h4>
          <h4 className="success -position">President</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />Mercer Community College</h4>
          <h4 className="success -subheader">Presidential Search</h4>
          <div className="success -story-container">
            <p>"I had the pleasure of working with
            Pauly Group both as an employer and as a candidate. In all of my
            interactions with Pauly Group, I found Angela Provart (President)
            to be exceptional in providing extremely detail-oriented and caring
            services. She is also exemplary in providing follow up services long
            after the service is complete."</p>
          </div>
          <h4 className="success -author">- Dr. Jianping Wang</h4>
          <h4 className="success -position">President</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />Mid Michigan College</h4>
          <h4 className="success -subheader">Presidential Search</h4>
          <div className="success -story-container">
            <p>“With professional compassion, Angela Provart,
            president of Pauly Group listens, adapts, and expertly leads your
            college community through the presidential search process.”</p>
          </div>
          <h4 className="success -author">- Terry Petrongelli</h4>
          <h4 className="success -position">Board Member and Presidential Search Committee Chair</h4>
        </div>
        <div className="success -testimonial">
          <h4 className="success -testimonial-header"><ArrowRightRoundedIcon className="success -arrow" />Stark State College</h4>
          <h4 className="success -subheader">Referencing</h4>
          <div className="success -story-container">
            <p>"For several years, Stark State College
            has relied on the Pauly Group to provide comprehensive and timely
            reference checks that are critical to our talent management needs.
            We find Angela Provart and her team to be consummate professionals
            who complement our College Human Resources and leadership team."</p>
          </div>
          <h4 className="success -author">- Dr. Para Jones</h4>
          <h4 className="success -position">President</h4>
        </div>
      </div>
    </Layout>
    );
}

export default SuccessStoriesPage
